import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import styles from "../../assets/jss/materialStyles/components/headerLinksStyle.js";
import { getCities, getCurrencies, getCountries } from '../../services/dashboard/actions.js';
// import { getAllRestaurants } from '../../services/restaurants/actions.js';
const useStyles = makeStyles(styles);

export default function CustomAutoComplete(props) {
    const classes = useStyles();
    // const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const dispatch = useDispatch();
    const handleSet = (v) => {
        props.setFunction(v);
        // setValue("");
    }
    React.useEffect(() => {
        setOptions(props.optionState);
    },[props.optionState]);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            // if (props.asyncFunction === 'rest_list')
            //     dispatch(getAllRestaurants)
            await dispatch(
                props.asyncFunction === "cities"?
                    getCities()
                : props.asyncFunction === "countries"?
                    getCountries()
                    : getCurrencies());

            if (active) {
                setOptions(props.optionState.filter(rest => rest.name && rest));
            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            value={props.value ? props.value : ''}
            className = {classes.root}
            onChange = {(event, value) => handleSet(value)}
            id="asynchronous-rest-call"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                if(props.inputChange){
                    props.inputChange(newInputValue)
                }
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name ? typeof option.name === 'string' ? option.name : option.name.en : ''}
            options={options}
            loading={loading}
            renderInput={params => (
                <TextField
                required
                {...params}
                label={props.label}
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
        />
    );
}
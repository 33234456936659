import React from "react";
import {
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Toolbar,
  IconButton,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import Chip from "@material-ui/core/Chip";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import Primary from "../../components/Typography/Primary.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

import styles from "../../assets/jss/materialStyles/views/dashboardStyle.js";
import {
  getDashboardData,
  setDashboardData,
  setAddCardDialog,
  getCities,
  getCountries,
  getCurrencies,
  addRestaurant,
  editRestaurant,
  deleteRestaurant,
  setEditCardDialog,
  getRestaurantGroups,
  setGroupDialog,
  setAddGroupCardDialog,
  addRestaurantGroup,
  fetchSwiggyMenu,
  getDashboardSearchData,
  activateRestaurant,
  removeStoreFromGroup,
  updateStoreGroup,
  uploadStoreGroupLogo,
} from "../../services/dashboard/actions";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomAutoComplete from "../../components/AutoComplete/AutoComplete.js";
import {
  getAllRestaurants,
  getGroupRestaurants,
  updateGroupRestaurants,
} from "../../services/restaurants/actions.js";
import RestaurantCard from "./modules/RestaurantCard.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MAP_KEY } from "../../config.js";
import Axios from "axios";
import RestaurantList from "./modules/RestaurantList.js";
import SearchRow from "./modules/SearchRow";
import RestaurantGroupList from "./modules/RestaurantGroupList.js";
import Card from "../../components/Card/Card.js";

// const access = {
//   swiggy: true,
//   storeTypes: ['covid essential', 'restaurant', 'grocery', 'fruits and vegetables', 'pharmacy', 'meat', 'stationary', 'pet supplies', 'sanitization services', 'organic foods', 'home bakers'],
//   displayName: true,
//   customization: ["loyalty","customLogo","customQR","QRVoucher","marketing","lastMile", "eatLater"],
//   orderTypes: ["dinein","takeaway","qsr","hotel","online","delivery"],
//   platforms: ['bolt', 'broeat', 'jumelia', 'marketmyindia'],
// }

// const access = {
//   swiggy: false,
//   storeTypes: ['covid essential', 'restaurant', 'grocery', 'fruits and vegetables', 'pharmacy', 'meat', 'stationary', 'pet supplies', 'sanitization services', 'organic foods',],
//   displayName: false,
//   customization: ["loyalty", "customLogo", "lastMile"],
//   orderTypes: ["delivery"],
//   platforms: ['marketmyindia'],
// }

const LANGUAGES = {
  Japanese: "ja",
  Estonian: "et",
  Russian: "ru",
  Spanish: "es",
  German: "de",
  Thai: "th",
  Malay: "ms",
  "Chinese (Simplified)": "zh",
  Filipino: "fil",
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicCusines: [
        "american",
        "asian",
        "chinese",
        "desserts",
        "european",
        "indian",
        "italian",
        "jain",
        "japanese",
        "korean",
        "lebanese",
        "lucknowi",
        "south indian",
        "thai",
        "cheesy",
        "chettinad",
        "continental",
        "grill",
        "gluten free",
        "healthy",
        "kebabs",
        "mughlai",
        "oriental",
        "regional",
        "sea food",
        "tandoor",
        "vegan",
        "biryani",
        "lunch",
        "thalis",
        "bakery",
        "beverages",
        "burgers",
        "chaat",
        "coffee & tea",
        "juices",
        "momos",
        "pizzas",
        "rolls and wrap",
        "sandwiches",
        "salads",
        "snacks",
        "local stores",
      ],
      search: "",
      groupName: null,
      groupType: null,
      currentGroup: {},
      tab: 0,
      restList: [],
      logoPreview: "",
      platforms: {
        broeat: false,
        bolt: false,
        marketmyindia: false,
      },
      logo: "",
      data: {
        name: {
          en: "",
        },
        visible_name: "",
        external_crm_id: "",
        business_name: "",
        loyalty_enabled: false,
        custom_logo: false,
        custom_qr: false,
        custom_whatsapp: false,
        book_a_table: false,
        crm_only_store: false,
        ita_direct_pay: false,
        order_food: false,
        qr_voucher: false,
        marketing: false,
        lastmile_available: false,
        eat_later: false,
        paytm_payments: false,
        retention: false,
        bad_ratings_msg_enabled: false,
        store_types: [],
        username: "",
        platforms: [],
        opted_languages: [],
        password: "",
        contact_number: "",
        person_of_contact_name: "",
        person_of_contact: "",
        owner_contact: "",
        email: "",
        bad_ratings_contact: "",
        order_types: [],
        cusines: [],
        address: {
          lat: null,
          lng: null,
          line1: "",
          line2: "",
          city: "",
          pincode: "",
          country: "",
        },
        payment_details: {
          GSTIN: "",
          PAN: "",
          bank_name: "",
          account_name: "",
          account_number: "",
          IFSC: "",
        },
        logo: "",
        subscription_plan: "month",
        subscription_price: null,
        swiggy_link: "",
        trial_period: null,
        default_currency: "",
      },
    };
  }
  handle_name = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };
  handle_name_en = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        name: { ...this.state.data.name, en: e.target.value },
      },
    });
  };
  handle_username = (e) => {
    this.setState({ data: { ...this.state.data, username: e.target.value } });
  };
  handle_password = (e) => {
    this.setState({ data: { ...this.state.data, password: e.target.value } });
  };
  handle_Store_type = (e) => {
    const temp = this.state.data.store_types;
    if (temp.includes(e.target.name)) {
      let findIndex = temp.findIndex((a) => a === e.target.name);
      temp.splice(findIndex, 1);
    } else {
      temp.push(e.target.name);
    }
    this.setState({ data: { ...this.state.data, store_types: temp } });
  };
  handle_visible_name = (e) => {
    this.setState({
      data: { ...this.state.data, visible_name: e.target.value },
    });
  };
  handle_business_name = (e) => {
    this.setState({
      data: { ...this.state.data, business_name: e.target.value },
    });
  };
  handle_external_crm_id = (e) => {
    this.setState({
      data: { ...this.state.data, external_crm_id: e.target.value },
    });
  };
  handle_contact_number = (e) => {
    this.setState({
      data: { ...this.state.data, contact_number: e.target.value },
    });
  };
  handle_person_of_contact_name = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        person_of_contact_name: e.target.value,
        person_of_contact: e.target.value,
      },
    });
  };
  handle_owner_contact = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        owner_contact: e.target.value,
      },
    });
  };
  handle_bad_ratings_contact = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        bad_ratings_contact: e.target.value,
      },
    });
  };
  handle_email = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        email: e.target.value,
      },
    });
  };
  handle_longitude = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, lng: e.target.value },
      },
    });
  };
  handle_latitude = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, lat: e.target.value },
      },
    });
  };
  handle_address1 = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, line1: e.target.value },
      },
    });
  };
  handle_address2 = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, line2: e.target.value },
      },
    });
  };
  handle_city = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, city: e },
      },
    });
  };
  handle_pincode = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, pincode: e.target.value },
      },
    });
  };
  handle_country = (v) => {
    let tempCurrency = {};
    if (v.currency_id) {
      this.props.currencies &&
        this.props.currencies.length > 0 &&
        this.props.currencies.forEach((currency) => {
          if (currency._id === v.currency_id) {
            tempCurrency = currency;
          }
        });
    }
    this.setState({
      data: {
        ...this.state.data,
        address: { ...this.state.data.address, country: v },
        default_currency: tempCurrency,
      },
    });
  };
  handle_GSTIN = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          GSTIN: e.target.value,
        },
      },
    });
  };
  handle_PAN = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          PAN: e.target.value,
        },
      },
    });
  };
  handle_bank_name = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          bank_name: e.target.value,
        },
      },
    });
  };
  handle_account_name = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          account_name: e.target.value,
        },
      },
    });
  };
  handle_account_number = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          account_number: e.target.value,
        },
      },
    });
  };
  handle_IFSC = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        payment_details: {
          ...this.state.data.payment_details,
          IFSC: e.target.value,
        },
      },
    });
  };
  handle_subscription_plan = (e) => {
    this.setState({
      data: { ...this.state.data, subscription_plan: e.target.value },
    });
  };
  handle_subscription_price = (e) => {
    this.setState({
      data: { ...this.state.data, subscription_price: e.target.value },
    });
  };
  handle_trial_period = (e) => {
    this.setState({
      data: { ...this.state.data, trial_period: e.target.value },
    });
  };
  handle_default_currency = (v) => {
    this.setState({ data: { ...this.state.data, default_currency: v } });
  };
  handle_order_types = (e, v) => {
    this.setState({ data: { ...this.state.data, order_types: v } });
  };
  handle_cusines = (e, v) => {
    this.setState({ data: { ...this.state.data, cusines: v } });
  };
  handle_opted_languages = (e, v) => {
    this.setState({
      data: { ...this.state.data, opted_languages: v.map((l) => LANGUAGES[l]) },
    });
  };
  handle_search = (e) => {
    this.setState({ search: e.target.value });
  };
  handleDelete = (rest) => {
    this.setState({
      deleteID: rest._id,
      deleteName: rest.name,
      deleteDialog: true,
    });
  };
  handleRemoveFromStoregroup = (rest) => {
    this.setState({
      deleteID: rest._id,
      deleteName: rest.name,
      removeFromGroupDialog: true,
    });
  };

  handleActive = (rest) => {
    this.props.activateRestaurant(rest);
  };
  handlePlatform = (event) => {
    this.setState({
      platforms: {
        ...this.state.platforms,
        [event.target.name]: event.target.checked,
      },
    });
  };
  handle_loyalty_enabled = (event) => {
    this.setState({
      data: { ...this.state.data, loyalty_enabled: event.target.checked },
    });
  };
  handle_custom_logo = (event) => {
    this.setState({
      data: { ...this.state.data, custom_logo: event.target.checked },
    });
  };

  handle_custom_qr = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        custom_logo: event.target.checked
          ? event.target.checked
          : this.state.data.custom_logo,
        custom_qr: event.target.checked,
      },
    });
  };

  handle_qr_voucher = (event) => {
    this.setState({
      data: { ...this.state.data, qr_voucher: event.target.checked },
    });
  };

  handle_marketing = (event) => {
    this.setState({
      data: { ...this.state.data, marketing: event.target.checked },
    });
  };

  handle_lastmile_available = (event) => {
    this.setState({
      data: { ...this.state.data, lastmile_available: event.target.checked },
    });
  };

  handle_eat_later = (event) => {
    this.setState({
      data: { ...this.state.data, eat_later: event.target.checked },
    });
  };

  handle_paytm_payments = (e) => {
    this.setState({
      data: { ...this.state.data, paytm_payments: e.target.checked },
    });
  };

  handle_retention = (e) => {
    this.setState({
      data: { ...this.state.data, retention: e.target.checked },
    });
  };

  handle_bad_ratings_msg_enabled = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        bad_ratings_msg_enabled: event.target.checked,
      },
    });
  };

  handle_custom_whatsapp = (event) => {
    this.setState({
      data: { ...this.state.data, custom_whatsapp: event.target.checked },
    });
  };

  handle_book_a_table = (event) => {
    this.setState({
      data: { ...this.state.data, book_a_table: event.target.checked },
    });
  };

  handle_crm_only_store = (event) => {
    this.setState({
      data: { ...this.state.data, crm_only_store: event.target.checked },
    });
  };

  handle_ita_direct_pay = (event) => {
    this.setState({
      data: { ...this.state.data, ita_direct_pay: event.target.checked },
    });
  };

  handle_order_food = (event) => {
    this.setState({
      data: { ...this.state.data, order_food: event.target.checked },
    });
  };

  handleLogo = async (event) => {
    event.preventDefault();

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const files = event.target.files;
    const form = new FormData();

    form.append("file", files[0]);

    const img64 = await toBase64(files[0]);

    this.setState({
      logoPreview: URL.createObjectURL(files[0]),
      logo: img64,
      data: { ...this.state.data, logo: files[0] },
    });

    // this.props.uploadLogo(bodyFormData);
  };

  handle_group_visible_name = (e) => {
    this.setState({
      currentGroup: {
        ...this.state.currentGroup,
        visible_name: e.target.value,
      },
    });
  };

  handleGroupUpdate = () => {
    let data = { visible_name: this.state.currentGroup.visible_name };
    this.props.storeGroupUpdate(this.state.currentGroup._id, data);
    this.handleGroupClose();
  };

  deleteUploadFile = () => {
    document.getElementById("upload-store-group-logo").value = "";
  };

  handleEdit = (rest) => {
    let tempPlatforms = {
      broeat: rest.platforms && rest.platforms.includes("broeat"),
      bolt: rest.platforms && rest.platforms.includes("bolt"),
      marketmyindia: rest.platforms && rest.platforms.includes("marketmyindia"),
    };

    this.setState({
      logoPreview: rest.logo,
      data: {
        ...rest,
        lastmile_available:
          rest.preferences &&
          rest.preferences.delivery &&
          rest.preferences.delivery.lastmile_available
            ? rest.preferences.delivery.lastmile_available
            : false,
        opted_languages: rest.opted_languages ? rest.opted_languages : [],
        bad_ratings_msg_enabled:
          rest.preferences &&
          rest.preferences.crm &&
          rest.preferences.crm.bad_ratings_msg_enabled,
        bad_ratings_contact:
          rest.preferences &&
          rest.preferences.crm &&
          rest.preferences.crm.bad_ratings_contact,
      },
      platforms: tempPlatforms,
    });
    this.props.setEditCardDialog(true);
  };

  handleAddClose = () => {
    this.setState({
      logo: "",
      logoPreview: "",
      data: {
        name: {
          en: "",
        },
        username: "",
        visible_name: "",
        business_name: "",
        external_crm_id: "",
        store_types: [],
        platforms: [],
        opted_languages: [],
        password: "",
        contact_number: "",
        person_of_contact_name: "",
        person_of_contact: "",
        owner_contact: "",
        email: "",
        bad_ratings_contact: "",
        loyalty_enabled: false,
        custom_logo: false,
        custom_qr: false,
        custom_whatsapp: false,
        book_a_table: false,
        crm_only_store: false,
        ita_direct_pay: false,
        order_food: false,
        qr_voucher: false,
        marketing: false,
        lastmile_available: false,
        eat_later: false,
        paytm_payments: false,
        retention: false,
        bad_ratings_msg_enabled: false,
        order_types: [],
        cusines: [],
        address: {
          lat: null,
          lng: null,
          line1: "",
          line2: "",
          city: "",
          pincode: "",
          country: "",
        },
        payment_details: {
          GSTIN: "",
          PAN: "",
          bank_name: "",
          account_name: "",
          account_number: "",
          IFSC: "",
        },
        logo: "",
        subscription_plan: "month",
        subscription_price: null,
        swiggy_link: "",
        trial_period: null,
        default_currency: "",
      },
      platforms: {
        bolt: false,
        broeat: false,
        marketmyindia: false,
      },
    });
    this.props.setAddCardDialog(false);
  };

  handleAddGroupClose = () => {
    this.setState({
      logo: "",
      logoPreview: "",
      data: {
        name: {
          en: "",
        },
        username: "",
        loyalty_enabled: false,
        custom_logo: false,
        custom_qr: false,
        custom_whatsapp: false,
        book_a_table: false,
        crm_only_store: false,
        ita_direct_pay: false,
        order_food: false,
        qr_voucher: false,
        marketing: false,
        lastmile_available: false,
        eat_later: false,
        paytm_payments: false,
        retention: false,
        bad_ratings_msg_enabled: false,
        platforms: [],
        visible_name: "",
        business_name: "",
        external_crm_id: "",
        store_types: [],
        opted_languages: [],
        password: "",
        contact_number: "",
        person_of_contact_name: "",
        person_of_contact: "",
        owner_contact: "",
        bad_ratings_contact: "",
        email: "",
        order_types: [],
        cusines: [],
        address: {
          lat: null,
          lng: null,
          line1: "",
          line2: "",
          city: "",
          pincode: "",
          country: "",
        },
        payment_details: {
          GSTIN: "",
          PAN: "",
          bank_name: "",
          account_name: "",
          account_number: "",
          IFSC: "",
        },
        logo: "",
        subscription_plan: "month",
        subscription_price: null,
        swiggy_link: "",
        trial_period: null,
        default_currency: "",
      },
      platforms: {
        bolt: false,
        broeat: false,
        marketmyindia: false,
      },
    });
    this.props.setAddGroupCardDialog(false);
  };

  handleGroupClose = () => {
    this.setState({
      currentGroup: {},
    });
    this.props.setGroupDialog(false);
  };

  handleEditClose = (rest) => {
    this.setState({
      data: {
        name: {
          en: "",
        },
        username: "",
        platforms: [],
        visible_name: "",
        business_name: "",
        external_crm_id: "",
        loyalty_enabled: false,
        custom_logo: false,
        custom_qr: false,
        custom_whatsapp: false,
        book_a_table: false,
        crm_only_store: false,
        ita_direct_pay: false,
        order_food: false,
        qr_voucher: false,
        marketing: false,
        lastmile_available: false,
        eat_later: false,
        paytm_payments: false,
        retention: false,
        bad_ratings_msg_enabled: false,
        store_types: [],
        opted_languages: [],
        password: "",
        contact_number: "",
        person_of_contact_name: "",
        person_of_contact: "",
        owner_contact: "",
        email: "",
        bad_ratings_contact: "",
        order_types: [],
        cusines: [],
        address: {
          lat: null,
          lng: null,
          line1: "",
          line2: "",
          city: "",
          pincode: "",
          country: "",
        },
        payment_details: {
          GSTIN: "",
          PAN: "",
          bank_name: "",
          account_name: "",
          account_number: "",
          IFSC: "",
        },
        logo: "",
        subscription_plan: "month",
        subscription_price: null,
        swiggy_link: "",
        trial_period: null,
        default_currency: "",
      },
      platforms: {
        bolt: false,
        broeat: false,
        marketmyindia: false,
      },
    });
    this.props.setEditCardDialog(false);
  };

  handleTabChange = (e, newVal) => {
    newVal === 0
      ? this.props.dashState.result.length === 0 &&
        this.props.getDashboardData()
      : this.props.groupData.result.length === 0 &&
        this.props.getRestaurantGroups();
    this.setState({ tab: newVal });
  };

  handleCurrentGroup = (group) => {
    this.setState({ currentGroup: group });
    this.props.setGroupDialog(true);
    this.props.getGroupRestaurants(group._id);
  };

  handle_rest_add = (rest) => {
    rest &&
      this.state.restList.findIndex((exrest) => exrest._id === rest._id) ===
        -1 &&
      this.setState({ restList: [...this.state.restList, rest] });
  };

  handle_rest_add_single = (rest) => {
    this.setState({
      restList: [{ ...rest }],
    });
  };

  handleInputChange = (value) => {
    this.props.getDashboardSearchData(value);
  };

  handleTypeChange = (event) => {
    this.setState({ groupType: event.target.value });
  };

  handleGroupSubmit = () => {
    const body = {
      name: this.state.groupName,
      username: this.state.data.username,
      password: this.state.data.password,
      __type: this.state.groupType,
      stores: [
        ...this.state.restList.filter((r) => !r.federate).map((r) => r._id),
      ],
      federated_stores: [
        ...this.state.restList.filter((r) => r.federate).map((r) => r._id),
      ],
      visible_name: this.state.data.visible_name,
    };
    this.props.addRestaurantGroup(body);
    this.setState({
      restList: [],
    });
  };

  handle_Swiggy_link = (e) => {
    this.setState({
      data: { ...this.state.data, swiggy_link: e.target.value },
    });
  };

  handleFederatedMenuChange = (store) => {
    let index = this.state.restList.findIndex((s) => s._id === store._id);
    if (index > -1) {
      let arr = [...this.state.restList];
      let temp = { ...arr[index] };
      temp["federate"] = !arr[index]["federate"];
      arr[index] = temp;
      this.setState({
        restList: [...arr],
      });
    }
  };

  storeResponse = async (data) => {
    let temp = {};
    if (data.name) {
      temp["name"] = {
        en: data.name,
      };
    }
    if (data.cusines && data.cusines.length > 0) {
      let dynCus = [...this.state.dynamicCusines];
      let cus = [];
      data.cusines.forEach((obj) => {
        if (!dynCus.includes(obj.toLowerCase())) {
          dynCus.push(obj.toLowerCase());
        }
        cus.push(obj.toLowerCase());
      });
      this.setState({
        dynamicCusines: dynCus,
      });
      temp["cusines"] = cus;
    }
    if (data.address) {
      let tempAddress = {
        lat: null,
        lng: null,
        line1: "",
        line2: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
      };
      if (data.address.lat && data.address.lng) {
        tempAddress["lat"] = data.address.lat;
        tempAddress["lng"] = data.address.lng;
        try {
          const res = await Axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.address.lat},${data.address.lng}&key=${MAP_KEY}`
          );
          if (res.data && res.data.results && res.data.results.length > 0) {
            let temp = res.data.results[0].formatted_address;
            tempAddress["line1"] = temp.split(data.address.line2)[0];
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (data.address.line2) tempAddress["line2"] = data.address.line2;
      if (data.address.pincode)
        tempAddress["pincode"] = parseInt(data.address.pincode);
      if (data.address.country) {
        this.props.countries.length > 0 &&
          this.props.countries.forEach((country) => {
            if (
              country.country_code.toLowerCase() ===
              data.address.country.toLowerCase()
            ) {
              tempAddress["country"] = country;
              if (country.currency_id) {
                this.props.currencies &&
                  this.props.currencies.length > 0 &&
                  this.props.currencies.forEach((currency) => {
                    if (currency._id === country.currency_id) {
                      temp["default_currency"] = currency;
                    }
                  });
              }
            }
          });
      }
      if (data.address.city) {
        this.props.cities.length > 0 &&
          this.props.cities.forEach((city) => {
            if (city.name.toLowerCase() === data.address.city.toLowerCase()) {
              tempAddress["city"] = city;
            }
          });
      }
      temp["address"] = tempAddress;
    }
    if (data.images && data.images.length > 0) {
      temp["logo"] = data.images[0];
    }
    this.setState({
      data: {
        ...this.state.data,
        name: temp.name,
        cusines: temp.cusines,
        address: temp.address,
        logo: temp.logo,
        default_currency: temp.default_currency ? temp.default_currency : "",
        order_types: ["takeaway", "delivery"],
      },
    });
  };

  componentDidMount = () => {
    this.props.getCities();
    this.props.getCountries();
    this.props.getCurrencies();
    this.props.getRestaurantGroups();
  };

  handle_get_swiggy_store = () => {
    this.props.fetchSwiggyMenu(this.state.data.swiggy_link, this.storeResponse);
  };
  icons = ["local_cafe", "local_dining", "restaurant"];
  render() {
    const { dashState, groupData, access } = this.props;
    return (
      <div>
        <Tabs
          value={this.state.tab}
          onChange={this.handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            key={0}
            label={`Stores ( ${
              dashState &&
              dashState.__metadata &&
              dashState.__metadata.total_count
                ? dashState.__metadata.total_count
                : 0
            } )`}
            classes={{ wrapper: this.props.classes.tabs }}
          />
          <Tab
            key={1}
            label={`Stores Group ( ${
              groupData &&
              groupData.__metadata &&
              groupData.__metadata.total_count
                ? groupData.__metadata.total_count
                : 0
            } )`}
            classes={{ wrapper: this.props.classes.tabs }}
          />
        </Tabs>
        {this.props.dashPending ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <CircularProgress
              className={this.props.classes.loginProgress}
              size={54}
              thickness={4}
            />
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "rgba(0, 0, 16, 0.57",
                marginLeft: 8,
              }}
            >
              Loading Stores...
            </div>
          </div>
        ) : (
          <>
            {this.state.tab === 0 ? (
              <GridContainer>
                <GridItem xs={12}>
                  <SearchRow handleEmptySearch={this.props.getDashboardData} />
                </GridItem>
                <GridItem xs={12}>
                  <RestaurantList
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleActive={this.handleActive}
                  />
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer>
                <RestaurantGroupList
                  handleCurrentGroup={this.handleCurrentGroup}
                />
              </GridContainer>
            )}

            {/*
_____________________________________________________

            DELETE CARD DIALOG

_____________________________________________________ */}

            <Dialog
              open={this.state.deleteDialog || this.state.removeFromGroupDialog}
              onClose={() => this.setState({ deleteDialog: false })}
            >
              <DialogTitle>
                {this.state.removeFromGroupDialog ? "Remove" : "Delete"}{" "}
                {this.state.deleteName &&
                  (typeof this.state.deleteName === "string"
                    ? this.state.deleteName
                    : this.state.deleteName.en)}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <b>Are You Sure</b>
                  You Want To
                  <Primary>
                    {" "}
                    {this.state.removeFromGroupDialog
                      ? "Remove"
                      : "Delete"}{" "}
                  </Primary>
                  This Restaurant?
                </DialogContentText>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        deleteDialog: false,
                        removeFromGroupDialog: false,
                      });
                    }}
                    simple
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.removeFromGroupDialog) {
                        this.props.removeStoreFromGroup(
                          this.state.currentGroup._id,
                          this.state.deleteID
                        );
                      } else {
                        this.props.deleteRestaurant(this.state.deleteID);
                      }
                      this.setState({
                        deleteDialog: false,
                        removeFromGroupDialog: false,
                      });
                    }}
                  >
                    {this.state.removeFromGroupDialog
                      ? "Remove Store"
                      : "Delete Store"}
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
            {/*
_____________________________________________________

            ADD RESTAURANT DIALOG

_____________________________________________________ */}

            <Dialog
              open={this.props.addCardDialog}
              onClose={this.handleAddClose}
            >
              <DialogTitle>
                <Toolbar>
                  <GridContainer direction="row" justify="space-between">
                    <GridItem alignContent="center">
                      <Typography
                        style={{ verticalAlign: "middle", marginTop: 10 }}
                      >
                        Add New Store
                      </Typography>
                    </GridItem>
                    <GridItem>
                      <IconButton
                        onClick={this.handleAddClose}
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                      >
                        <CloseIcon color="primary" />
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </Toolbar>
              </DialogTitle>
              <DialogContent>
                {access.swiggy && (
                  <GridContainer alignItems="center">
                    <GridItem xs={8}>
                      <TextField
                        className={this.props.classes.textField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={this.state.data.swiggy_link}
                        onChange={this.handle_Swiggy_link}
                        label="Swiggy link"
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.handle_get_swiggy_store}
                      >
                        Get store details
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.username}
                      onChange={this.handle_username}
                      label="Username"
                      autoFocus
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.password}
                      onChange={this.handle_password}
                      label="Password"
                    />
                  </GridItem>
                  {access.storeTypes && access.storeTypes.length > 0 && (
                    <GridItem xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Store Type</FormLabel>
                        <FormGroup row>
                          {access.storeTypes.map((store, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    this.state.data.store_types &&
                                    this.state.data.store_types.includes(store)
                                  }
                                  onChange={this.handle_Store_type}
                                  name={store}
                                />
                              }
                              label={store}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  )}
                  {access.displayName && (
                    <GridItem xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={this.state.data.visible_name}
                        onChange={this.handle_visible_name}
                        label="Display/Visible name"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.business_name}
                      onChange={this.handle_business_name}
                      label="Business Name"
                    />
                  </GridItem>
                  {access.external_crm_id && (
                    <GridItem xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={this.state.data.external_crm_id}
                        onChange={this.handle_external_crm_id}
                        label="External CRM ID"
                      />
                    </GridItem>
                  )}
                  {access.customization && access.customization.length > 0 && (
                    <GridItem xs={12}>
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: 20 }}
                      >
                        <FormLabel component="legend">Customization</FormLabel>
                        <FormGroup row>
                          {access.customization.includes("loyalty") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.loyalty_enabled}
                                  onChange={this.handle_loyalty_enabled}
                                />
                              }
                              label={"Loyalty"}
                            />
                          )}
                          {access.customization.includes("customLogo") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_logo}
                                  onChange={this.handle_custom_logo}
                                />
                              }
                              label={"Custom logo"}
                            />
                          )}
                          {access.customization.includes("customQR") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_qr}
                                  onChange={this.handle_custom_qr}
                                />
                              }
                              label={"Custom QR"}
                            />
                          )}
                          {access.customization.includes("custom_whatsapp") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_whatsapp}
                                  onChange={this.handle_custom_whatsapp}
                                />
                              }
                              label={"Custom Whatsapp"}
                            />
                          )}
                          {access.customization.includes("book_a_table") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.book_a_table}
                                  onChange={this.handle_book_a_table}
                                />
                              }
                              label={"Book table option"}
                            />
                          )}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.data.crm_only_store}
                                onChange={this.handle_crm_only_store}
                              />
                            }
                            label={"CRM only store"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.data.ita_direct_pay}
                                onChange={this.handle_ita_direct_pay}
                              />
                            }
                            label={"ITA Direct Pay"}
                          />
                          {access.customization.includes("order_food") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.order_food}
                                  onChange={this.handle_order_food}
                                />
                              }
                              label={"Order food option"}
                            />
                          )}
                          {access.customization.includes("QRVoucher") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.qr_voucher}
                                  onChange={this.handle_qr_voucher}
                                />
                              }
                              label={"Voucher QR"}
                            />
                          )}
                          {access.customization.includes("marketing") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.marketing}
                                  onChange={this.handle_marketing}
                                />
                              }
                              label={"Marketing"}
                            />
                          )}
                          {access.customization.includes("lastMile") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.lastmile_available}
                                  onChange={this.handle_lastmile_available}
                                />
                              }
                              label={"Lastmile Available"}
                            />
                          )}
                          {access.customization.includes("eatLater") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.eat_later}
                                  onChange={this.handle_eat_later}
                                />
                              }
                              label={"Eat Later"}
                            />
                          )}
                          {access.customization.includes("paytm_payments") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.paytm_payments}
                                  onChange={this.handle_paytm_payments}
                                />
                              }
                              label={"Payments - paytm"}
                            />
                          )}
                          {access.customization.includes("retention") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.retention}
                                  onChange={this.handle_retention}
                                />
                              }
                              label={"Retention"}
                            />
                          )}
                          {access.customization.includes(
                            "bad_ratings_msg_enabled"
                          ) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state.data.bad_ratings_msg_enabled
                                  }
                                  onChange={this.handle_bad_ratings_msg_enabled}
                                />
                              }
                              label={"Bad Ratings"}
                            />
                          )}
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  )}

                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.name.en}
                      onChange={this.handle_name_en}
                      label="Name (English)"
                    />
                  </GridItem>
                  {access.orderTypes && access.orderTypes.length > 0 && (
                    <GridItem xs={12}>
                      <Autocomplete
                        className={this.props.classes.textField}
                        multiple
                        options={access.orderTypes}
                        value={this.state.data.order_types}
                        getOptionLabel={(option) => option}
                        style={{ width: "100%", margin: "16px 0" }}
                        onChange={this.handle_order_types}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={this.props.classes.textField}
                            required
                            variant="outlined"
                            label="Accepted Order Types"
                            placeholder="Add Types"
                          />
                        )}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <Autocomplete
                      className={this.props.classes.textField}
                      multiple
                      options={this.state.dynamicCusines}
                      value={this.state.data.cusines}
                      onChange={this.handle_cusines}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={this.props.classes.textField}
                          variant="outlined"
                          label="Cuisines"
                          placeholder="Add Cuisines"
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h5>LANGUAGES</h5>
                    </DialogContentText>
                    <DialogContentText>
                      <p>
                        Please select <b>at most 2 languages</b> apart from
                        English{" "}
                        <i>
                          (English is a default language accross all regions)
                        </i>
                      </p>
                    </DialogContentText>
                    <DialogContentText>
                      <Primary>
                        NOTE: Languages once selected will{" "}
                        <i>
                          <b>
                            <u>NOT</u>
                          </b>
                        </i>{" "}
                        be editable after store registration.
                      </Primary>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <Autocomplete
                      className={this.props.classes.textField}
                      multiple
                      options={
                        this.state.data.opted_languages.length >= 2
                          ? ["Only 2 Languages Allowed."]
                          : Object.keys(LANGUAGES)
                      }
                      getOptionDisabled={(option) =>
                        option === "Only 2 Languages Allowed."
                      }
                      defaultValue={[]}
                      onChange={this.handle_opted_languages}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={this.props.classes.textField}
                          required
                          variant="outlined"
                          label="Opted Languages"
                          placeholder="Add Languages"
                        />
                      )}
                    />
                  </GridItem>
                  {access.platforms && access.platforms.length > 0 && (
                    <>
                      <GridItem xs={12}>
                        <DialogContentText>
                          <h5>PLATFORMS</h5>
                        </DialogContentText>
                      </GridItem>
                      <GridItem xs={12}>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {access.platforms.includes("broeat") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.broeat}
                                    onChange={this.handlePlatform}
                                    name="broeat"
                                  />
                                }
                                label="Broeat"
                              />
                            )}
                            {access.platforms.includes("bolt") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.bolt}
                                    onChange={this.handlePlatform}
                                    name="bolt"
                                  />
                                }
                                label="Bolt"
                              />
                            )}
                            {access.platforms.includes("marketmyindia") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.marketmyindia}
                                    onChange={this.handlePlatform}
                                    name="marketmyindia"
                                  />
                                }
                                label="Marketmyindia"
                              />
                            )}
                          </FormGroup>
                          <FormHelperText>
                            Select the platforms you want your presence in.
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                    </>
                  )}
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.contact_number}
                      onChange={this.handle_contact_number}
                      label="Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.person_of_contact_name}
                      onChange={this.handle_person_of_contact_name}
                      label="Person of Contact Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.owner_contact}
                      onChange={this.handle_owner_contact}
                      label="Owner Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.bad_ratings_contact}
                      onChange={this.handle_bad_ratings_contact}
                      label="Bad Ratings Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.email}
                      onChange={this.handle_email}
                      label="Email"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      value={this.state.data.trial_period}
                      onChange={this.handle_trial_period}
                      label="Trial Period (Days)"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h3>ADDRESS</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.line1}
                      onChange={this.handle_address1}
                      label="Address Line 1"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      placeholder={"Enter area to be displayed in bolt"}
                      value={this.state.data.address.line2}
                      onChange={this.handle_address2}
                      label="Address Line 2 (area)"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomAutoComplete
                      label="Search Your City"
                      asyncFunction="cities"
                      value={this.state.data.address.city}
                      setFunction={this.handle_city}
                      optionState={this.props.cities}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      value={this.state.data.address.pincode}
                      onChange={this.handle_pincode}
                      label="Pincode"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomAutoComplete
                      label="Search Your Country"
                      asyncFunction="countries"
                      value={this.state.data.address.country}
                      setFunction={this.handle_country}
                      optionState={this.props.countries}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <h5>Location</h5>
                  </GridItem>
                  <GridItem xs={12}>
                    <Divider />
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.lat}
                      onChange={this.handle_latitude}
                      label="Lat."
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.lng}
                      onChange={this.handle_longitude}
                      label="Long."
                    />
                  </GridItem>
                  {/* <GridItem xs={12}>
                    <DialogContentText>
                      <h3>PAYMENT</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.GSTIN}
                      onChange={this.handle_GSTIN}
                      label="GSTIN"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.PAN}
                      onChange={this.handle_PAN}
                      label="PAN"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.bank_name}
                      onChange={this.handle_bank_name}
                      label="Bank Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.account_name}
                      onChange={this.handle_account_name}
                      label="Account Holder's Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.account_number}
                      onChange={this.handle_account_number}
                      label="Account Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.IFSC}
                      onChange={this.handle_IFSC}
                      label="IFSC"
                    />
                  </GridItem> */}
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h3>SUBSCRIPTION</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={6}>
                    <FormControl className={this.props.classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Subscription Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.data.subscription_plan}
                        onChange={this.handle_subscription_plan}
                        outlined
                        style={{ width: "100%", margin: "16px 0" }}
                      >
                        <MenuItem value={"month"}>Monthly</MenuItem>
                        <MenuItem value={"quater"}>Quaterly</MenuItem>
                        <MenuItem value={"half_year"}>Half-Yearly</MenuItem>
                        <MenuItem value={"year"}>Annually</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      value={this.state.data.subscription_price}
                      onChange={this.handle_subscription_price}
                      label="Rate"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomAutoComplete
                      label="Search Your Currency"
                      asyncFunction="currencies"
                      value={this.state.data.default_currency}
                      setFunction={this.handle_default_currency}
                      optionState={this.props.currencies}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <h3>ADD LOGO</h3>
                  </GridItem>
                  <GridItem xs={12}>
                    <input
                      accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                      className={this.props.classes.input}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={this.handleLogo}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="raised"
                        color="primary"
                        fullWidth
                        component="div"
                        className={this.props.classes.button}
                      >
                        Upload LOGO
                      </Button>
                    </label>
                  </GridItem>
                  <GridItem xs={12}>
                    <img
                      width="70%"
                      style={{ margin: "15%" }}
                      src={this.state.logoPreview}
                      alt=""
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div>Feilds marked (*) are Required</div>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        this.handleAddClose();
                      }}
                      simple
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            platforms: Object.keys(this.state.platforms).filter(
                              (a) => this.state.platforms[a]
                            ),
                          },
                        });
                        this.props.addRestaurant(
                          {
                            ...this.state.data,
                            platforms: Object.keys(this.state.platforms).filter(
                              (a) => this.state.platforms[a]
                            ),
                          },
                          this.handleAddClose()
                        );
                      }}
                      disabled={
                        this.state.data.username === "" ||
                        this.state.data.password === "" ||
                        (this.state.data.store_types &&
                          this.state.data.store_types.length === 0) ||
                        this.state.data.name.en === "" ||
                        this.state.data.address.lat === null ||
                        this.state.data.address.lng === null ||
                        this.state.data.address.line1 === "" ||
                        this.state.data.address.line2 === "" ||
                        this.state.data.address.city === "" ||
                        this.state.data.address.pincode === null ||
                        this.state.data.address.country === "" ||
                        this.state.data.email === "" ||
                        this.state.data.default_currency === "" ||
                        this.state.data.order_types === []
                      }
                    >
                      ADD Store
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Dialog>
            {/*
_____________________________________________________

            ADD GROUP DIALOG

_____________________________________________________ */}

            <Dialog
              open={this.props.addGroupCardDialog}
              onClose={this.handleAddGroupClose}
            >
              <DialogTitle>
                <Toolbar>
                  <GridContainer direction="row" justify="space-between">
                    <GridItem alignContent="center">
                      <Typography
                        style={{ verticalAlign: "middle", marginTop: 10 }}
                      >
                        Add New Store Group
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </Toolbar>
              </DialogTitle>
              <DialogContent>
                <GridContainer>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.username}
                      onChange={this.handle_username}
                      label="Username"
                      autoFocus
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.password}
                      onChange={this.handle_password}
                      label="Password"
                      type="password"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.name}
                      onChange={this.handle_name}
                      label="name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.visible_name}
                      onChange={this.handle_visible_name}
                      label="Display/Visible name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <FormControl
                      component="fieldset"
                      className={this.props.classes.radios}
                    >
                      <FormLabel component="legend">{"Group Type"}</FormLabel>
                      <RadioGroup
                        row
                        value={this.state.groupType}
                        onChange={this.handleTypeChange}
                      >
                        <FormControlLabel
                          value={"food_court"}
                          control={<CustomRadio />}
                          label={"Food Court"}
                        />
                        <FormControlLabel
                          value={"franchise"}
                          control={<CustomRadio />}
                          label={"Franchise"}
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12}>
                    <List>
                      <ListSubheader>
                        <Primary>Store units</Primary>
                      </ListSubheader>
                      {this.state.restList.map((rest, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={
                                typeof rest.name === "string"
                                  ? rest.name
                                  : rest.name.en
                              }
                            />
                            <ListItemSecondaryAction>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rest.federate}
                                    onChange={() =>
                                      this.handleFederatedMenuChange(rest)
                                    }
                                  />
                                }
                                label={"Federated menu"}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomAutoComplete
                      label="Add Stores"
                      asyncFunction="rest_list"
                      setFunction={this.handle_rest_add}
                      optionState={dashState.result}
                      inputChange={this.handleInputChange}
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.handleAddGroupClose();
                  }}
                  simple
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleGroupSubmit();
                    this.props.setAddGroupCardDialog(false);
                    this.handleAddGroupClose();
                  }}
                  disabled={this.state.data.password === ""}
                >
                  ADD STORE GROUP
                </Button>
              </DialogActions>
            </Dialog>
            {/*
_____________________________________________________

            GROUP Expand DIALOG

_____________________________________________________ */}

            <Dialog
              open={this.props.groupDialog}
              onClose={this.handleGroupClose}
              maxWidth={"lg"}
            >
              <DialogTitle>
                <Toolbar>
                  <GridContainer direction="row" justify="space-between">
                    <GridItem alignContent="center">
                      <Typography
                        variant="h5"
                        style={{ verticalAlign: "middle", marginTop: 10 }}
                      >
                        {this.state.currentGroup.name}
                      </Typography>
                    </GridItem>
                    {/* <GridItem style={{ display: "flex", flexDirection: "row" }}>
                      <Primary>
                        <IconButton
                          onClick={() => handleGroupDelete(rest)}
                          edge="end"
                          color="inherit"
                          aria-label="menu"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Primary>
                    </GridItem> */}
                  </GridContainer>
                </Toolbar>
              </DialogTitle>
              <DialogContent>
                {access.displayName && (
                  <GridContainer alignItems="center">
                    <GridItem>
                      <TextField
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.currentGroup.visible_name}
                        onChange={this.handle_group_visible_name}
                        label="Display/Visible name"
                      />
                    </GridItem>
                    <GridItem>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => this.handleGroupUpdate()}
                      >
                        Update
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                {
                  <GridContainer style={{ marginTop: 16, marginBottom: 16 }}>
                    <GridItem>
                      {this.state.currentGroup &&
                        this.state.currentGroup.logo && (
                          <img
                            src={this.state.currentGroup.logo}
                            alt=""
                            height={64}
                            width={64}
                          />
                        )}
                    </GridItem>
                    <GridItem>
                      <Button color={"primary"} variant={"outlined"}>
                        Upload logo
                        <input
                          style={{
                            zIndex: 1000,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            opacity: 0,
                            left: 0,
                            cursor: "pointer",
                          }}
                          id="upload-store-group-logo"
                          type="file"
                          multiple={false}
                          accept="image/*"
                          onChange={(e) => {
                            this.props.uploadStoreGroupLogo(
                              this.state.currentGroup._id,
                              e.target.files[0]
                            );
                            this.handleGroupClose();
                            this.deleteUploadFile();
                          }}
                          onClick={() => {
                            this.deleteUploadFile();
                          }}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                }
                {this.props.groupRestaurants &&
                this.props.groupRestaurants.result ? (
                  <GridContainer>
                    {this.props.groupRestaurants.result.map((rest, index) => {
                      return (
                        <RestaurantCard
                          variant="restaurant"
                          index={index}
                          rest={rest}
                          groupRest
                          handleEdit={this.handleEdit}
                          handleDelete={this.handleRemoveFromStoregroup}
                        />
                      );
                    })}
                    <GridItem xs={12} sm={6} md={6} alignItems={"center"}>
                      <Card
                        onClick={() => this.setState({ addStoreToGroup: true })}
                      >
                        <div style={{ cursor: "pointer", padding: 21 }}>
                          <h2>
                            <strong>+ Add Store</strong>
                          </h2>
                        </div>
                      </Card>
                    </GridItem>
                    {/* <div style={{border: '1px solid lightGray', padding: 30, margin: 16, borderRadius: 4}}>
                      + ADD STORE
                    </div> */}
                  </GridContainer>
                ) : (
                  <CircularProgress style={{ margin: "60px auto" }} />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.handleGroupClose();
                  }}
                  simple
                >
                  BACK
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.addStoreToGroup}
              onClose={() =>
                this.setState({ addStoreToGroup: false, restList: [] })
              }
            >
              <DialogTitle>
                <div style={{ padding: 16 }}>
                  <strong>Add Store</strong>
                </div>
              </DialogTitle>
              <DialogContent>
                <GridItem xs={12} style={{ width: 400 }}>
                  <List>
                    <ListSubheader>
                      <Primary>Store units</Primary>
                    </ListSubheader>
                    {this.state.restList.map((rest, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              typeof rest.name === "string"
                                ? rest.name
                                : rest.name.en
                            }
                          />
                          <ListItemSecondaryAction>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={rest.federate}
                                  onChange={() =>
                                    this.handleFederatedMenuChange(rest)
                                  }
                                />
                              }
                              label={"Federated menu"}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </GridItem>
                <GridItem xs={12}>
                  <CustomAutoComplete
                    label="Add Store"
                    asyncFunction="rest_list"
                    setFunction={this.handle_rest_add_single}
                    optionState={dashState.result}
                    inputChange={this.handleInputChange}
                  />
                </GridItem>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState({ addStoreToGroup: false, restList: [] });
                  }}
                  simple
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.updateGroupRestaurants(
                      this.state.currentGroup._id,
                      this.state.restList
                    );
                    this.setState({ restList: [], addStoreToGroup: false });
                  }}
                  simple
                >
                  ADD
                </Button>
              </DialogActions>
            </Dialog>
            {/*
_____________________________________________________

            EDIT CARD DIALOG

_____________________________________________________ */}

            <Dialog
              open={this.props.editCardDialog}
              onClose={this.handleEditClose}
            >
              <DialogTitle>
                <Toolbar justify>
                  <Typography style={{ verticalAlign: "middle" }}>
                    Edit Store
                  </Typography>
                  <IconButton
                    onClick={this.handleEditClose}
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </Toolbar>
              </DialogTitle>
              <DialogContent p={5}>
                <GridContainer>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.name.en}
                      onChange={this.handle_name_en}
                      label="Name"
                    />
                  </GridItem>
                  {access.displayName && (
                    <GridItem xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={this.state.data.visible_name}
                        onChange={this.handle_visible_name}
                        label="Display/Visible name"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.business_name}
                      onChange={this.handle_business_name}
                      label="Business Name"
                    />
                  </GridItem>
                  {access.external_crm_id && (
                    <GridItem xs={12}>
                      <TextField
                        className={this.props.classes.textField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={this.state.data.external_crm_id}
                        onChange={this.handle_external_crm_id}
                        label="External CRM ID"
                      />
                    </GridItem>
                  )}
                  {access.storeTypes && access.storeTypes.length > 0 && (
                    <GridItem xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Store Type</FormLabel>
                        <FormGroup row>
                          {access.storeTypes.map((store, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={
                                    this.state.data.store_types &&
                                    this.state.data.store_types.includes(store)
                                  }
                                  onChange={this.handle_Store_type}
                                  name={store}
                                />
                              }
                              label={store}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  )}
                  {access.orderTypes && access.orderTypes.length > 0 && (
                    <GridItem xs={12}>
                      <Autocomplete
                        className={this.props.classes.textField}
                        multiple
                        options={access.orderTypes}
                        value={this.state.data.order_types}
                        getOptionLabel={(option) => option}
                        style={{ width: "100%", margin: "16px 0" }}
                        onChange={this.handle_order_types}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={this.props.classes.textField}
                            variant="outlined"
                            required
                            label="Accepted Order Types"
                            placeholder="Add Types"
                          />
                        )}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <Autocomplete
                      className={this.props.classes.textField}
                      multiple
                      options={this.state.dynamicCusines}
                      defaultValue={this.state.data.cusines}
                      onChange={this.handle_cusines}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={this.props.classes.textField}
                          variant="outlined"
                          label="Cuisines"
                          placeholder="Add Cuisines"
                        />
                      )}
                    />
                  </GridItem>
                  {access.customization && access.customization.length > 0 && (
                    <GridItem xs={12}>
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: 20 }}
                      >
                        <FormLabel component="legend">Customization</FormLabel>
                        <FormGroup row>
                          {access.customization.includes("loyalty") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.loyalty_enabled}
                                  onChange={this.handle_loyalty_enabled}
                                />
                              }
                              label={"Loyalty"}
                            />
                          )}
                          {access.customization.includes("customLogo") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_logo}
                                  onChange={this.handle_custom_logo}
                                />
                              }
                              label={"Custom logo"}
                            />
                          )}
                          {access.customization.includes("customQR") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_qr}
                                  onChange={this.handle_custom_qr}
                                />
                              }
                              label={"Custom QR"}
                            />
                          )}
                          {access.customization.includes("custom_whatsapp") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.custom_whatsapp}
                                  onChange={this.handle_custom_whatsapp}
                                />
                              }
                              label={"Custom Whatsapp"}
                            />
                          )}
                          {access.customization.includes("book_a_table") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.book_a_table}
                                  onChange={this.handle_book_a_table}
                                />
                              }
                              label={"Book table option"}
                            />
                          )}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.data.crm_only_store}
                                onChange={this.handle_crm_only_store}
                              />
                            }
                            label={"CRM only store"}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.data.ita_direct_pay}
                                onChange={this.handle_ita_direct_pay}
                              />
                            }
                            label={"ITA Direct Pay"}
                          />
                          {access.customization.includes("order_food") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.order_food}
                                  onChange={this.handle_order_food}
                                />
                              }
                              label={"Order food option"}
                            />
                          )}
                          {access.customization.includes("QRVoucher") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.qr_voucher}
                                  onChange={this.handle_qr_voucher}
                                />
                              }
                              label={"Voucher QR"}
                            />
                          )}
                          {access.customization.includes("marketing") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.marketing}
                                  onChange={this.handle_marketing}
                                />
                              }
                              label={"Marketing"}
                            />
                          )}
                          {access.customization.includes("lastMile") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.lastmile_available}
                                  onChange={this.handle_lastmile_available}
                                />
                              }
                              label={"Lastmile Available"}
                            />
                          )}
                          {access.customization.includes("eatLater") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.eat_later}
                                  onChange={this.handle_eat_later}
                                />
                              }
                              label={"Eat Later"}
                            />
                          )}
                          {access.customization.includes("paytm_payments") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.paytm_payments}
                                  onChange={this.handle_paytm_payments}
                                />
                              }
                              label={"Payments - paytm"}
                            />
                          )}
                          {access.customization.includes("retention") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.data.retention}
                                  onChange={this.handle_retention}
                                />
                              }
                              label={"Retention"}
                            />
                          )}
                          {access.customization.includes(
                            "bad_ratings_msg_enabled"
                          ) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state.data.bad_ratings_msg_enabled
                                  }
                                  onChange={this.handle_bad_ratings_msg_enabled}
                                />
                              }
                              label={"Bad Ratings"}
                            />
                          )}
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h5>LANGUAGES</h5>
                    </DialogContentText>
                    <DialogContentText>
                      <p>
                        These are the opted languages for this store apart from
                        English{" "}
                        <i>
                          (English is a default language accross all regions)
                        </i>
                      </p>
                    </DialogContentText>
                    <DialogContentText>
                      <Primary>
                        NOTE: Languages once selected are{" "}
                        <i>
                          <b>
                            <u>NOT</u>
                          </b>
                        </i>{" "}
                        editable after store registration.
                      </Primary>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <Autocomplete
                      className={this.props.classes.textField}
                      multiple
                      disabled={true}
                      options={
                        this.state.data.opted_languages.length >= 2
                          ? ["Only 2 Languages Allowed."]
                          : Object.keys(LANGUAGES)
                      }
                      getOptionDisabled={(option) =>
                        option === "Only 2 Languages Allowed."
                      }
                      defaultValue={this.state.data.opted_languages.map((ol) =>
                        Object.keys(LANGUAGES).find((l) => LANGUAGES[l] === ol)
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={this.props.classes.textField}
                          required
                          variant="outlined"
                          label="Opted Languages"
                          placeholder="Add Languages"
                        />
                      )}
                    />
                  </GridItem>
                  {access.platforms && access.platforms.length > 0 && (
                    <>
                      <GridItem xs={12}>
                        <DialogContentText>
                          <h5>PLATFORMS</h5>
                        </DialogContentText>
                      </GridItem>
                      <GridItem xs={12}>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {access.platforms.includes("broeat") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.broeat}
                                    onChange={this.handlePlatform}
                                    name="broeat"
                                  />
                                }
                                label="Broeat"
                              />
                            )}
                            {access.platforms.includes("bolt") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.bolt}
                                    onChange={this.handlePlatform}
                                    name="bolt"
                                  />
                                }
                                label="Bolt"
                              />
                            )}
                            {access.platforms.includes("marketmyindia") && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.platforms.marketmyindia}
                                    onChange={this.handlePlatform}
                                    name="marketmyindia"
                                  />
                                }
                                label="Marketmyindia"
                              />
                            )}
                          </FormGroup>
                          <FormHelperText>
                            Select the platforms you want your presence in.
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                    </>
                  )}
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.contact_number}
                      onChange={this.handle_contact_number}
                      label="Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.person_of_contact_name}
                      onChange={this.handle_person_of_contact_name}
                      label="Person of Contact Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.owner_contact}
                      onChange={this.handle_owner_contact}
                      label="Owner Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.bad_ratings_contact}
                      onChange={this.handle_bad_ratings_contact}
                      label="Bad Ratings Contact Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.email}
                      onChange={this.handle_email}
                      label="Email"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      value={this.state.data.trial_period}
                      onChange={this.handle_trial_period}
                      label="Trial Period (Days)"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h3>ADDRESS</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.line1}
                      onChange={this.handle_address1}
                      label="Address Line 1"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      placeHolder={"area to be shown in bolt"}
                      value={this.state.data.address.line2}
                      onChange={this.handle_address2}
                      label="Address Line 2 (area)"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomAutoComplete
                      label="Search Your City"
                      asyncFunction="cities"
                      value={this.state.data.address.city}
                      setFunction={this.handle_city}
                      optionState={this.props.cities}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      value={this.state.data.address.pincode}
                      onChange={this.handle_pincode}
                      label="Pincode"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <h6>
                      <b>Country: </b>
                      <i>{this.state.data.address.country.name}</i>
                    </h6>
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.lat}
                      onChange={this.handle_latitude}
                      label="Lat."
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.address.lng}
                      onChange={this.handle_longitude}
                      label="Long."
                    />
                  </GridItem>
                  {/* <GridItem xs={12}>
                    <DialogContentText>
                      <h3>PAYMENT</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.GSTIN}
                      onChange={this.handle_GSTIN}
                      label="GSTIN"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.PAN}
                      onChange={this.handle_PAN}
                      label="PAN"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.bank_name}
                      onChange={this.handle_bank_name}
                      label="Bank Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.account_name}
                      onChange={this.handle_account_name}
                      label="Account Holder's Name"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.account_number}
                      onChange={this.handle_account_number}
                      label="Account Number"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.data.payment_details && this.state.data.payment_details.IFSC}
                      onChange={this.handle_IFSC}
                      label="IFSC"
                    />
                  </GridItem> */}
                  <GridItem xs={12}>
                    <DialogContentText>
                      <h3>SUBSCRIPTION</h3>
                    </DialogContentText>
                  </GridItem>
                  <GridItem xs={6}>
                    <FormControl className={this.props.classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Subscription Plan
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.data.subscription_plan}
                        onChange={this.handle_subscription_plan}
                        outlined
                        style={{ width: "100%" }}
                      >
                        <MenuItem value={"month"}>Monthly</MenuItem>
                        <MenuItem value={"quater"}>Quaterly</MenuItem>
                        <MenuItem value={"half_year"}>Half-Yearly</MenuItem>
                        <MenuItem value={"year"}>Annually</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6}>
                    <TextField
                      className={this.props.classes.textField}
                      variant="outlined"
                      margin="normal"
                      type="number"
                      fullWidth
                      value={this.state.data.subscription_price}
                      onChange={this.handle_subscription_price}
                      label="Rate"
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <h3>EDIT LOGO</h3>
                  </GridItem>
                  <GridItem xs={12}>
                    <input
                      accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                      className={this.props.classes.input}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={this.handleLogo}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        variant="raised"
                        color="primary"
                        fullWidth
                        component="div"
                        className={this.props.classes.button}
                      >
                        Upload LOGO
                      </Button>
                    </label>
                  </GridItem>
                  <GridItem xs={12}>
                    <img
                      width="70%"
                      style={{ margin: "15%" }}
                      src={this.state.logoPreview}
                      alt=""
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.handleEditClose();
                  }}
                  simple
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        platforms: Object.keys(this.state.platforms).filter(
                          (a) => this.state.platforms[a]
                        ),
                      },
                    });
                    this.props.editRestaurant(
                      {
                        ...this.state.data,
                        platforms: Object.keys(this.state.platforms).filter(
                          (a) => this.state.platforms[a]
                        ),
                      },
                      this.handleEditClose()
                    );
                  }}
                  disabled={
                    this.state.data.name.en === "" ||
                    this.state.data.address.lat === null ||
                    this.state.data.address.lng === null ||
                    this.state.data.address.line1 === "" ||
                    this.state.data.address.line2 === "" ||
                    this.state.data.address.city === "" ||
                    this.state.data.address.pincode === null ||
                    this.state.data.address.country === "" ||
                    this.state.data.order_types === []
                  }
                >
                  EDIT STORE
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Divider
          className={
            this.props.classes.margin + " " + this.props.classes.search
          }
        />
        <br />
        <i>WTF - Where's The Food</i>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashFilterState:
      state.dashboardFilter && state.dashboardFilter.dashboardFilter,
    dashFilterPending:
      state.dashboardFilter && state.dashboardFilter.filterPending,
    dashState: state.dashboard.dashboardData,
    groupData: state.dashboard.groupData,
    groupRestaurants: state.restaurants.groupRestaurants,
    dashPending: state.dashboard.dashboardPending,
    editCardDialog: state.dashboard.editCardDialog,
    addCardDialog: state.dashboard.addCardDialog,
    addGroupCardDialog: state.dashboard.addGroupCardDialog,
    groupDialog: state.dashboard.groupDialog,
    cities: state.dashboard.cities,
    countries: state.dashboard.countries,
    currencies: state.dashboard.currencies,
    access: state.auth.authData && state.auth.authData.access,
  };
};

const CustomRadio = withStyles({
  root: {
    padding: "6px 10px !important",
    "&$checked": { color: "#F53B57" },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: () => dispatch(getDashboardData()),
    getAllRestaurants: () => dispatch(getAllRestaurants()),
    getGroupRestaurants: (id) => dispatch(getGroupRestaurants(id)),
    getRestaurantGroups: () => dispatch(getRestaurantGroups()),
    setDashboardData: (data) => dispatch(setDashboardData(data)),
    setAddCardDialog: (data) => dispatch(setAddCardDialog(data)),
    setAddGroupCardDialog: (data) => dispatch(setAddGroupCardDialog(data)),
    setGroupDialog: (data) => dispatch(setGroupDialog(data)),
    setEditCardDialog: (data) => dispatch(setEditCardDialog(data)),
    getCities: () => dispatch(getCities()),
    getCountries: () => dispatch(getCountries()),
    getCurrencies: () => dispatch(getCurrencies()),
    addRestaurant: (data, cb) => dispatch(addRestaurant(data, cb)),
    addRestaurantGroup: (data) => dispatch(addRestaurantGroup(data)),
    editRestaurant: (data, cb) => dispatch(editRestaurant(data, cb)),
    deleteRestaurant: (data) => dispatch(deleteRestaurant(data)),
    fetchSwiggyMenu: (data, cb) => dispatch(fetchSwiggyMenu(data, cb)),
    getDashboardSearchData: (value) => dispatch(getDashboardSearchData(value)),
    activateRestaurant: (data) => dispatch(activateRestaurant(data)),
    removeStoreFromGroup: (id, storeID) =>
      dispatch(removeStoreFromGroup(id, storeID)),
    updateGroupRestaurants: (id, data) =>
      dispatch(updateGroupRestaurants(id, data)),
    storeGroupUpdate: (id, data) => dispatch(updateStoreGroup(id, data)),
    uploadStoreGroupLogo: (id, file) =>
      dispatch(uploadStoreGroupLogo(id, file)),
    // uploadLogo: (img, id) => dispatch(uploadLogo(img, id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard));

import React from "react";
import classNames from "classnames";
import {connect, useDispatch} from 'react-redux';
import PropTypes from "prop-types";
  
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
 
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "../CustomButtons/Button.js";

import styles from "../../assets/jss/materialStyles/components/headerStyle.js";
import { toggleDarkTheme } from "../../services/theme/actions.js";
import { setAddCardDialog, setAddGroupCardDialog } from "../../services/dashboard/actions.js";
import { uploadFile, uploadFileSwiggy } from "../../services/restaurants/actions";

const useStyles = makeStyles(styles);

function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  const deleteUploadFile = () => {
    document.getElementById('upload-excel').value = '';
  };

  const deleteUploadFileSwiggy = () => {
    document.getElementById('upload-excel-swiggy').value = '';
  }

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = 'https://d3f679ocn1f9ln.cloudfront.net/store_upload.xlsx';
    link.click();
  }

  const handleSwiggyDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = 'https://d3f679ocn1f9ln.cloudfront.net/store_upload_swiggy.xlsx';
    link.click();
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
            {makeBrand() === "Stores" && <><Button
                color={"primary"}
                variant="outlined"
                size={'small'}
                onClick={() => dispatch(setAddCardDialog(true))}
              >
                ADD
              </Button>
              <Button
                color={"primary"}
                variant="outlined"
                size={'small'}
                onClick={() => dispatch(setAddGroupCardDialog(true))}
              >
                ADD GROUP 
              </Button>
              <Button
                color={"primary"}
                variant="outlined"
                size={'small'}
              >
                Bulk Upload
                <input
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0,
                  }}
                  id="upload-excel"
                  type="file"
                  multiple={false}
                  accept=".xlsx"
                  onChange={(e) => {
                    dispatch(uploadFile(e.target.files[0]));
                  }}
                  onClick={() => {
                    deleteUploadFile();
                  }}
                /> 
              </Button>
              <span style={{
                fontSize: 12,
                fontWeight: 400,
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
                marginRight: 10,
              }} onClick={handleDownloadTemplate}>
                Store template
              </span>
              <Button
                size={'small'}
                color={"primary"}
                variant="outlined"
              >
                Bulk Upload with swiggy
                <input
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0,
                  }}
                  id="upload-excel-swiggy"
                  type="file"
                  multiple={false}
                  accept=".xlsx"
                  onChange={(e) => {
                    dispatch(uploadFileSwiggy(e.target.files[0]));
                  }}
                  onClick={() => {
                    deleteUploadFileSwiggy();
                  }}
                /> 
              </Button>
              <span style={{
                fontSize: 12,
                fontWeight: 400,
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  color: 'red',
                },
                marginRight: 10,
              }} onClick={handleSwiggyDownloadTemplate}>
                Swiggy store template 
              </span></>
            }
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = (state) => {
  return {
    darkTheme: state.theme.darkTheme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDarkTheme: () => dispatch(toggleDarkTheme()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
import * as actions from "../actionTypes";

const initState = {
  dashboardData: {
    result: [],
    __metadata: {}
  },
  groupData: {
    result: [],
    __metadata: {}
  },
  dashboardPending: false,
  dashboardError: {},
  groupDialog: false,
  addCardDialog: false,
  addGroupCardDialog: false,
  editCardDialog: false,
  cities: [],
  countries: [],
  currencies: [],
  formPending: false,
};

export const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_DASHBOARD_DATA:
      return Object.assign({}, state, {
        dashboardData: Object.assign(state.dashboardData, action.payload),
      });
    case actions.EDIT_RESTAURANT_SUCCESS: {
      let temp = {...state.dashboardData};
      if(temp.result && temp.result.length > 0){
        let index = temp.result.findIndex(a => a._id === action.payload._id);
        if(index > -1){
          temp.result[index] = action.payload;
        }
      }
      return {
        ...state,
        dashboardData: temp
      }
    }
    case actions.GET_DASHBOARD_DATA_PAGINATION_SUCCESS: {
      let temp = {...state.dashboardData};
      if(temp.result){
        action.payload.result && action.payload.result.length > 0 && action.payload.result.forEach(res => {
          temp.result.push(res);
        })
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        dashboardData: temp
      }
    }
    case actions.GET_RESTAURANT_GROUPS_PAGINATION_SUCCESS: {
      let temp = {...state.groupData};
      if(temp.result){
        action.payload.result && action.payload.result.length > 0 && action.payload.result.forEach(res => {
          temp.result.push(res);
        })
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        groupData: temp
      }
    }
    case action.GET_DASHBOARD_DATA_SEARCH_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload
      }
    case actions.SET_ADD_CARD_DIALOG:
      return Object.assign({}, state, { addCardDialog: action.payload });
    case actions.SET_GROUP_DIALOG:
      return Object.assign({}, state, { groupDialog: action.payload });
    case actions.SET_ADD_GROUP_CARD_DIALOG:
      return Object.assign({}, state, { addGroupCardDialog: action.payload });
    case actions.SET_EDIT_CARD_DIALOG:
      return Object.assign({}, state, { editCardDialog: action.payload });
    case actions.GET_DASHBOARD_DATA_PENDING:
      return Object.assign({}, state, { dashboardPending: true });
    case actions.GET_DASHBOARD_DATA_SUCCESS:
      return Object.assign({}, state, {
        dashboardData: action.payload.data,
        dashboardPending: false,
      });
    case actions.GET_DASHBOARD_DATA_FAILED:
      return Object.assign({}, state, {
        dashboardError: action.payload.data,
        dashboardPending: false,
      });
    case actions.GET_RESTAURANT_GROUPS_PENDING:
      return Object.assign({}, state, { dashboardPending: true });
    case actions.GET_RESTAURANT_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        groupData: action.payload.data,
        dashboardPending: false,
      });
    case actions.GET_RESTAURANT_GROUPS_FAILED:
      return Object.assign({}, state, {
        dashboardError: action.payload.data,
        dashboardPending: false,
      });
    case actions.GET_CITIES_PENDING:
      return Object.assign({}, state, { formPending: true });
    case actions.GET_CITIES_SUCCESS:
      return Object.assign({}, state, {
        cities: action.payload.data,
        formPending: false,
      });
    case actions.GET_CITIES_FAILED:
      return Object.assign({}, state, {
        dashboardError: action.payload.data,
        formPending: false,
      });
    case actions.GET_COUNTRIES_PENDING:
      return Object.assign({}, state, { formPending: true });
    case actions.GET_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        countries: action.payload.data,
        formPending: false,
      });
    case actions.GET_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        dashboardError: action.payload.data,
        formPending: false,
      });
    case actions.GET_CURRENCIES_PENDING:
      return Object.assign({}, state, { formPending: true });
    case actions.GET_CURRENCIES_SUCCESS:
      return Object.assign({}, state, {
        currencies: action.payload.data,
        formPending: false,
      });
    case actions.GET_CURRENCIES_FAILED:
      return Object.assign({}, state, {
        dashboardError: action.payload.data,
        formPending: false,
      });
    default:
      return state;
  }
};

const initRestState = {
  dashboardFilter: {},
  filterPending: false,
  filterError: "",
};

export const dashboardFilterReducer = (state = initRestState, action) => {
  switch (action.type) {
    case actions.GET_DASHBOARD_FILTER_PENDING:
      return Object.assign({}, state, { filterPending: true });
    case actions.GET_DASHBOARD_FILTER_SUCCESS:
      return Object.assign({}, state, {
        dashboardFilter: action.payload.data,
        filterPending: false,
      });
    case actions.GET_DASHBOARD_FILTER_FAILED:
      return Object.assign({}, state, {
        filterError: action.payload.data,
        filterPending: false,
      });
    default:
      return state;
  }
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RestaurantCard from './RestaurantCard';
import { getStoreListPagination } from '../../../services/dashboard/actions';
import InfiniteScroll from "react-infinite-scroll-component";

function RestaurantList(props) {
  const storeList = useSelector((state) => state.dashboard.dashboardData);
  const dispatch = useDispatch();

  const getData = () => {
    if(storeList.__metadata && storeList.__metadata.links){
      if(storeList.__metadata.links[3].next){
        dispatch(getStoreListPagination(storeList.__metadata.links[3].next))
      }
    }
  };

  return (
    <InfiniteScroll
      dataLength={storeList && storeList.result && storeList.result.length ? storeList.result.length : 0}
      next={getData}
      hasMore={storeList && storeList.result && (storeList.result.length < storeList.__metadata.total_count)}
      loader={<h4>Loading...</h4>}
    >
      <div style={{display: 'flex', flexWrap: "wrap"}}>
        {storeList && storeList.result && storeList.result.length > 0 && 
          storeList.result.map((store, index) => (
            <RestaurantCard
              variant="restaurant"
              index={index}
              rest={store}
              handleEdit={props.handleEdit}
              handleDelete={props.handleDelete}
              handleActive={props.handleActive}
            />
          ))}
      </div>
    </InfiniteScroll>
  );
};

export default RestaurantList;
import React, { useState, useEffect } from 'react';
import { useDebounce } from '../../../utils/debounce';
import { useDispatch } from 'react-redux';
import { getDashboardSearchEmptyData, getDashboardSearchData } from '../../../services/dashboard/actions';
import TextField from '@material-ui/core/TextField';

function SearchRow(){
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 400);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.trim !== '') {
      dispatch(getDashboardSearchData(debouncedSearchTerm));
    } else if (debouncedSearchTerm === ''){
      dispatch(getDashboardSearchEmptyData());
    }
  }, [debouncedSearchTerm, dispatch]);

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      label="Search Store (user name/store name)"
      autoFocus
    />
  )
}

export default SearchRow;
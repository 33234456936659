export const TOGGLE_THEME = "TOGGLE_THEME";

export const GET_DASHBOARD_DATA_PENDING = "GET_DASHBOARD_DATA_PENDING";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";
export const GET_DASHBOARD_DATA_PAGINATION_SUCCESS = "GET_DASHBOARD_DATA_PAGINATION_SUCCESS";
export const GET_DASHBOARD_DATA_SEARCH_SUCCESS = "GET_DASHBOARD_DATA_SEARCH_SUCCESS";

export const LOGIN_ADMIN_PENDING = "LOGIN_ADMIN_PENDING";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILED = "LOGIN_ADMIN_FAILED";

export const GET_GROUP_RESTAURANTS_PENDING = "GET_GROUP_RESTAURANTS_PENDING";
export const GET_GROUP_RESTAURANTS_SUCCESS = "GET_GROUP_RESTAURANTS_SUCCESS";
export const GET_GROUP_RESTAURANTS_FAILED = "GET_GROUP_RESTAURANTS_FAILED";
export const GET_RESTAURANT_GROUPS_PAGINATION_SUCCESS = "GET_RESTAURANT_GROUPS_PAGINATION_SUCCESS";

export const GET_ALL_RESTAURANTS_PENDING = "GET_ALL_RESTAURANTS_PENDING";
export const GET_ALL_RESTAURANTS_SUCCESS = "GET_ALL_RESTAURANTS_SUCCESS";
export const GET_ALL_RESTAURANTS_FAILED = "GET_ALL_RESTAURANTS_FAILED";

export const GET_RESTAURANT_GROUPS_PENDING = "GET_RESTAURANT_GROUPS_PENDING";
export const GET_RESTAURANT_GROUPS_SUCCESS = "GET_RESTAURANT_GROUPS_SUCCESS";
export const GET_RESTAURANT_GROUPS_FAILED = "GET_RESTAURANT_GROUPS_FAILED";

export const GET_DASHBOARD_FILTER_PENDING = "GET_DASHBOARD_FILTER_PENDING";
export const GET_DASHBOARD_FILTER_SUCCESS = "GET_DASHBOARD_FILTER_SUCCESS";
export const GET_DASHBOARD_FILTER_FAILED = "GET_DASHBOARD_FILTER_FAILED";

export const GET_CITIES_PENDING = "GET_CITIES_PENDING";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILED = "GET_CITIES_FAILED";

export const GET_COUNTRIES_PENDING = "GET_COUNTRIES_PENDING";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export const GET_CURRENCIES_PENDING = "GET_CURRENCIES_PENDING";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILED = "GET_CURRENCIES_FAILED";

export const ADD_RESTAURANT_PENDING = "ADD_RESTAURANT_PENDING";
export const ADD_RESTAURANT_SUCCESS = "ADD_RESTAURANT_SUCCESS";
export const ADD_RESTAURANT_FAILED = "ADD_RESTAURANT_FAILED";

export const ADD_RESTAURANT_GROUP_PENDING = "ADD_RESTAURANT_GROUP_PENDING";
export const ADD_RESTAURANT_GROUP_SUCCESS = "ADD_RESTAURANT_GROUP_SUCCESS";
export const ADD_RESTAURANT_GROUP_FAILED = "ADD_RESTAURANT_GROUP_FAILED";

export const EDIT_RESTAURANT_PENDING = "EDIT_RESTAURANT_PENDING";
export const EDIT_RESTAURANT_SUCCESS = "EDIT_RESTAURANT_SUCCESS";
export const EDIT_RESTAURANT_FAILED = "EDIT_RESTAURANT_FAILED";

export const DELETE_RESTAURANT_PENDING = "DELETE_RESTAURANT_PENDING";
export const DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS";
export const DELETE_RESTAURANT_FAILED = "DELETE_RESTAURANT_FAILED";

export const ADD_LOGO_PENDING = "ADD_LOGO_PENDING";
export const ADD_LOGO_SUCCESS = "ADD_LOGO_SUCCESS";
export const ADD_LOGO_FAILED = "ADD_LOGO_FAILED";


export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const SET_ADD_CARD_DIALOG = "SET_ADD_CARD_DIALOG";
export const SET_GROUP_DIALOG = "SET_GROUP_DIALOG";
export const SET_ADD_GROUP_CARD_DIALOG = "SET_ADD_GROUP_CARD_DIALOG";
export const SET_EDIT_CARD_DIALOG = "SET_EDIT_CARD_DIALOG";
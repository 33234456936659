import React, { Fragment, Suspense } from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "./views/Dashboard/Dashboard.js";
import LoginPage from "./views/Login/Login.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Switch, Route, Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "./components/Navbars/Navbar.js";
import Sidebar from "./components/Sidebar/Sidebar.js";
import styles from "./assets/jss/materialStyles/layouts/adminStyle.js";
import logo from "./assets/img/logo192.png";
import { withRouter } from "react-router-dom";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Stores",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: LoginPage,
    layout: "/admin"
  },
];

const useStyles = makeStyles(styles);

function Router({ darkTheme, isLoggedIn, history, ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutes}
        logoText={"WTF Sales"}
        logo={logo}
        isLoggedIn={isLoggedIn}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
    
      <div className={classes.mainPanel} style = {{backgroundColor: darkTheme ? "#222" : "initial" }} ref={mainPanel}>
        <Navbar
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
          <div className={classes.content}>
            <div className={classes.container}>
              <Fragment>
                <Suspense
                  fallback={<CircularProgress />}
                >
                  <Switch>
                    {dashboardRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.layout + prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    })}
                    {isLoggedIn ?
                      <Redirect from="/" to="/admin/dashboard" />
                    : <Redirect from="/" to="/admin/login" />
                    }
                  </Switch>
                </Suspense>
              </Fragment>
            </div>
          </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    darkTheme: state.theme.darkTheme,
    isLoggedIn: state.auth.isLoggedIn
  }
}
export default connect(mapStateToProps)(withRouter(Router));
import React from "react";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core";
import styles from "../../../assets/jss/materialStyles/views/dashboardStyle.js";
// @material-ui/icons
import AllInboxRounded from "@material-ui/icons/AllInboxRounded";
import { IconButton } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.js";
import Primary from "../../../components/Typography/Primary.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import Tooltip from '@material-ui/core/Tooltip';

const icons = ["local_cafe", "local_dining", "restaurant"];
const RestaurantCard = ({variant, onclick, index, rest, classes, handleEdit, handleDelete, handleActive, groupRest}) => {
  return (
    <GridItem xs={12} sm={6} md={6}>
      <Card active={rest.active || variant === "group"}>
        <CardHeader color="primary" stats icon>
          <CardIcon color="primary">
            <Icon>{icons[index % 3]}</Icon>
          </CardIcon>
          <h3 className={classes.cardTitle}>{typeof rest.name === 'string' ? rest.name : rest.name && rest.name.en}</h3>
        </CardHeader>
        {variant !== "group" ? (
          <CardFooter stats>
            <div className={classes.stats}>
              <Primary>
                <AllInboxRounded />
              </Primary>
              {rest.address &&
                `${rest.address.city && rest.address.city.name}, ${
                  rest.address.city && rest.address.city.state
                }, ${rest.address.country && rest.address.country.name}`}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {!groupRest && <Primary>
                <Tooltip title={'Edit Store'}>
                  <IconButton
                    onClick={() => handleEdit(rest)}
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Icon>create</Icon>
                  </IconButton>
                </Tooltip>
              </Primary>}
              <Primary>
                {rest.active ? <Tooltip title={'Deactivate Store'}>
                  <IconButton
                    onClick={() => handleDelete(rest)}
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip> : <Tooltip title={'Activate Store'}>
                  <IconButton
                    onClick={() => handleActive(rest)}
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                  >
                    <Icon>done</Icon>
                  </IconButton>
                </Tooltip>}
              </Primary>
            </div>
          </CardFooter>
        ) : (
          <CardFooter stats>
            <div className={classes.stats}>
              <Primary>
                <AllInboxRounded />
              </Primary>
              {rest.__type}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Primary>
                <IconButton
                  onClick = {() => variant === "group" && onclick(rest)}
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                >
                  <Icon>create</Icon>
                </IconButton>
              </Primary>
              <Primary>
                <IconButton
                  onClick={() => handleDelete(rest)}
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Primary>
            </div>
          </CardFooter>
        )}
      </Card>
    </GridItem>
  );
};

export default withStyles(styles)(RestaurantCard);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RestaurantCard from './RestaurantCard';
import { getStoreGroupListPagination, deleteStoreGroup } from '../../../services/dashboard/actions';
import InfiniteScroll from "react-infinite-scroll-component";

function RestaurantGroupList(props) {
  const storeList = useSelector((state) => state.dashboard.groupData);
  const dispatch = useDispatch();

  const getData = () => {
    if(storeList.__metadata && storeList.__metadata.links){
      if(storeList.__metadata.links[3].next){
        dispatch(getStoreGroupListPagination(storeList.__metadata.links[3].next))
      }
    }
  };

  const handleDeleteGroup = (group) => {
    if(group._id){
      dispatch(deleteStoreGroup(group._id))
    }
  }

  return (
    <InfiniteScroll
      dataLength={storeList && storeList.result && storeList.result.length ? storeList.result.length : 0}
      next={getData}
      hasMore={storeList && storeList.result && (storeList.result.length < storeList.__metadata.total_count)}
      loader={<h4>Loading...</h4>}
    >
      <div style={{display: 'flex', flexWrap: "wrap"}}>
        {storeList && storeList.result && storeList.result.length > 0 && 
          storeList.result.map((store, index) => (
            <RestaurantCard
              variant="group"
              index={index}
              rest={store}
              onclick={props.handleCurrentGroup}
              handleDelete={handleDeleteGroup}
            />
          ))}
      </div>
    </InfiniteScroll>
  );
};

export default RestaurantGroupList;
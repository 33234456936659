import * as actions from "../actionTypes";
import { push } from "connected-react-router";
import Axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from "../../config";
import { getDashboardData } from "../dashboard/actions";

export const getAllRestaurants = () => async (dispatch, getState) => {
    dispatch({ type: actions.GET_ALL_RESTAURANTS_PENDING });
    try{
        const res = await Axios
        .get(`${API_URL}/stores?limit=10&page=1`)
        await dispatch({ type: actions.GET_ALL_RESTAURANTS_SUCCESS, payload: res });
        await dispatch(push("/admin/dashboard"));
    } catch(err) {
        dispatch({ type: actions.GET_ALL_RESTAURANTS_FAILED, payload: err });
        toast.error("unable to get dashboard info");
    }
};

export const getGroupRestaurants = (rest_id) => async (dispatch) => {
    dispatch({ type: actions.GET_GROUP_RESTAURANTS_PENDING });
    try{
        const res = await Axios
        .get(`${API_URL}/store_groups/${rest_id}/stores`)
        await dispatch({ type: actions.GET_GROUP_RESTAURANTS_SUCCESS, payload: res });
    } catch(err) {
        dispatch({ type: actions.GET_GROUP_RESTAURANTS_FAILED, payload: err });
        toast.error("unable to get dashboard info");
    }
};

export const updateGroupRestaurants = (groupID, store) => async (dispatch, getState) => {
    dispatch({ type: actions.GET_GROUP_RESTAURANTS_PENDING });
    const token = getState().auth.authData.token;
    const data = {
        store_id: store && store.length > 0 && store[0]._id,
        federated: false, 
    };
    if(store && store.length > 0 && store[0].federate) {
        data['federated'] = true;
    }
    Axios
    .post(`${API_URL}/store_groups/${groupID}`, data,  {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    .then(() => {
        dispatch(getGroupRestaurants(groupID));
    })
    .catch((err) => {
        dispatch({ type: actions.GET_GROUP_RESTAURANTS_FAILED, payload: err });
        toast.error("unable to update store");
    })
};

export const uploadFile = (receivedFile) => {
    return (dispatch, getState) => {
        const data = new FormData();
        const token = getState().auth.authData.token;
        data.append('file', receivedFile);
        Axios
            .post(`${API_URL}/stores/excel`, data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(async (res) => {
                if (res.data.error_count === 0) {
                if (res.data.added_count > 0 || res.data.updated_count > 0) {
                    toast.success('successfully updated');
                } else {
                    toast.warning('nothing updated/added');
                }
                } else if (res.data.added_count > 0 || res.data.updated_count > 0) {
                toast.warning('partially updated');
                } else {
                toast.error('error found in file');
                }
                dispatch(getDashboardData())
            })
            .catch(() => {
                toast.error('unable to upload file');
            });
    }
}

export const uploadFileSwiggy = (receivedFile) => {
    return (dispatch, getState) => {
        const data = new FormData();
        const token = getState().auth.authData.token;
        data.append('file', receivedFile);
        Axios
            .post(`${API_URL}/store_groups/excel`, data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(async (res) => {
                if (res.data.error_count === 0) {
                if (res.data.added_count > 0 || res.data.updated_count > 0) {
                    toast.success('successfully updated');
                } else {
                    toast.warning('nothing updated/added');
                }
                } else if (res.data.added_count > 0 || res.data.updated_count > 0) {
                toast.warning('partially updated');
                } else {
                toast.error('error found in file');
                }
                dispatch(getDashboardData())
            })
            .catch(() => {
                toast.error('unable to upload file');
            });
    }
}

import * as actions from "../actionTypes";
import { push } from "connected-react-router";
import Axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../config";
import { getGroupRestaurants } from "../restaurants/actions";

export const setDashboardData = (payload) => {
  return {
    type: actions.SET_DASHBOARD_DATA,
    payload: payload,
  };
};

export const setAddCardDialog = (payload) => {
  return {
    type: actions.SET_ADD_CARD_DIALOG,
    payload: payload,
  };
};

export const setGroupDialog = (payload) => {
  return {
    type: actions.SET_GROUP_DIALOG,
    payload: payload,
  };
};

export const setAddGroupCardDialog = (payload) => {
  return {
    type: actions.SET_ADD_GROUP_CARD_DIALOG,
    payload: payload,
  };
};

export const setEditCardDialog = (payload) => {
  return {
    type: actions.SET_EDIT_CARD_DIALOG,
    payload: payload,
  };
};

export const getCities = () => async (dispatch, getState) => {
  dispatch({ type: actions.GET_CITIES_PENDING });
  try {
    const res = await Axios.get(`${API_URL}/city?limit=10000&page=1`);
    await dispatch({ type: actions.GET_CITIES_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: actions.GET_CITIES_FAILED, payload: err });
  }
};

export const getCountries = () => async (dispatch, getState) => {
  dispatch({ type: actions.GET_COUNTRIES_PENDING });
  try {
    const res = await Axios.get(`${API_URL}/country?limit=10000&page=1`);
    await dispatch({ type: actions.GET_COUNTRIES_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: actions.GET_COUNTRIES_FAILED, payload: err });
  }
};

export const getCurrencies = () => async (dispatch, getState) => {
  dispatch({ type: actions.GET_CURRENCIES_PENDING });
  try {
    const res = await Axios.get(`${API_URL}/currency`);
    await dispatch({ type: actions.GET_CURRENCIES_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: actions.GET_CURRENCIES_FAILED, payload: err });
  }
};

export const addRestaurant = (data, cb) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.ADD_RESTAURANT_PENDING });
  try {
    const res = await Axios.post(
      `${API_URL}/stores`,
      { ...data, logo: "" },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(cb){
      await cb();
    }
    await dispatch(getDashboardData());
    await dispatch({ type: actions.ADD_RESTAURANT_SUCCESS, payload: res });
    await dispatch(addLogo(res.data.restaurant._id, data.logo));
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.ADD_RESTAURANT_FAILED, payload: err });
  }
};

export const addRestaurantGroup = (data, cb) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.ADD_RESTAURANT_GROUP_PENDING });
  try {
    const res = await Axios.post(`${API_URL}/store_groups`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await dispatch({
      type: actions.ADD_RESTAURANT_GROUP_SUCCESS,
      payload: res,
    });
    if(cb){
      await cb();
    }
    await dispatch(getRestaurantGroups());
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.ADD_RESTAURANT_GROUP_FAILED, payload: err });
  }
};

export const updateStoreGroup = (id, data) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.GET_RESTAURANT_GROUPS_PENDING });
  try {
    const res = await Axios.put(
      `${API_URL}/store_groups/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(res.data) {
      await dispatch(getRestaurantGroups())
    }
    // await dispatch(getDashboardData());
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.GET_RESTAURANT_GROUPS_FAILED, payload: err });
    dispatch(getRestaurantGroups())
  }
};

export const addLogo = (id, logo) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  const form = new FormData();
  form.append("file", logo);
  try {
    const res = await Axios.post(`${API_URL}/stores/${id}/logo`, form, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        accept: "application/json",
      },
    });
    await dispatch({ type: actions.EDIT_RESTAURANT_SUCCESS, payload: res.data.store });
    toast.success(res.data.message);
    
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.GET_RESTAURANT_GROUPS_FAILED, payload: err });
  }
};

export const uploadStoreGroupLogo = (id, logo) => {
  return async (dispatch, getState) => {
    const token = getState().auth.authData.token;
    const data = new FormData();
    data.append('file', logo);
    dispatch({ type: actions.GET_RESTAURANT_GROUPS_PENDING });
    try {
      const res = await Axios.post(`${API_URL}/stores/${id}/logo?type=storegroup`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          accept: "application/json",
        },
      });
      if(res.data) {
        await dispatch(getRestaurantGroups())
      }
      
    } catch (err) {
      if (err && err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
      dispatch({ type: actions.ADD_LOGO_FAILED, payload: err });
      dispatch(getRestaurantGroups())
    }
  }
}

export const editRestaurant = (data, cb) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.EDIT_RESTAURANT_PENDING });
  const tempData = {...data};
  if(typeof data.logo !== 'string'){
    delete tempData.logo;
  }
  try {
    const res = await Axios.put(
      `${API_URL}/stores/${data._id}`,
      { ...tempData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    await dispatch({ type: actions.EDIT_RESTAURANT_SUCCESS, payload: res.data.Store });
    if(cb){
      await cb();
    }
    if(data.logo && typeof data.logo !== 'string'){
      console.log("logo", data.logo)
      await dispatch(addLogo(res.data.Store._id, data.logo))
    }
    toast.success(res.data.mesage)
    // await dispatch(getDashboardData());
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.EDIT_RESTAURANT_FAILED, payload: err });
  }
};

export const activateRestaurant = (data) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.EDIT_RESTAURANT_PENDING });
  try {
    const res = await Axios.put(
      `${API_URL}/stores/${data._id}`, { active: true },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    await dispatch({ type: actions.EDIT_RESTAURANT_SUCCESS, payload: res.data.Store });
    toast.success(res.data.mesage)
  } catch (err) {
    if (err && err.response && err.response.data) {
      toast.error(err.response.data.message);
    }
    dispatch({ type: actions.EDIT_RESTAURANT_FAILED, payload: err });
  }
};

export const deleteRestaurant = (id) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.DELETE_RESTAURANT_PENDING });
  try {
    const res = await Axios.delete(`${API_URL}/stores/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await dispatch({ type: actions.DELETE_RESTAURANT_SUCCESS, payload: res });
    await dispatch(getDashboardData());
  } catch (err) {
    dispatch({ type: actions.DELETE_RESTAURANT_FAILED, payload: err });
  }
};

export const removeStoreFromGroup = (id, storeID) => async (dispatch, getState) => {
  const token = getState().auth.authData.token;
  dispatch({ type: actions.DELETE_RESTAURANT_PENDING });
  try {
    const res = await Axios.delete(`${API_URL}/store_groups/${id}/${storeID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await dispatch({ type: actions.DELETE_RESTAURANT_SUCCESS, payload: res });
    await dispatch(getGroupRestaurants(id));
  } catch (err) {
    dispatch({ type: actions.DELETE_RESTAURANT_FAILED, payload: err });
  }
}

export const getDashboardData = (rest_id, fromDate, toDate) => async (
  dispatch,
  getState
) => {
  if (!rest_id && !fromDate && !toDate) {
    dispatch({ type: actions.GET_DASHBOARD_DATA_PENDING });
    // const bearerToken = getState().auth.authData.token;
    try {
      const res = await Axios.get(`${API_URL}/stores?limit=10&page=1`);
      await dispatch({
        type: actions.GET_DASHBOARD_DATA_SUCCESS,
        payload: res,
      });
      await dispatch(push("/admin/dashboard"));
    } catch (err) {
      dispatch({ type: actions.GET_DASHBOARD_DATA_FAILED, payload: err });
      toast.error("unable to get dashboard info");
    }
  } else {
    dispatch({ type: actions.GET_DASHBOARD_FILTER_PENDING });
    const bearerToken = getState().auth.authData.token;
    var res;
    try {
      if (!rest_id && !fromDate && toDate) {
        res = await Axios.get(`${API_URL}/admin/dashboard?to=${toDate}`, {
          headers: { Authorization: `Bearer ${bearerToken}` },
        });
      } else if (!rest_id && fromDate && !toDate) {
        res = await Axios.get(`${API_URL}/admin/dashboard?from=${fromDate}`, {
          headers: { Authorization: `Bearer ${bearerToken}` },
        });
      } else if (!rest_id && fromDate && toDate) {
        res = await Axios.get(
          `${API_URL}/admin/dashboard?from=${fromDate}&to=${toDate}`,
          {
            headers: { Authorization: `Bearer ${bearerToken}` },
          }
        );
      } else if (rest_id && !fromDate && !toDate) {
        res = await Axios.get(`${API_URL}/admin/dashboard?rest_id=${rest_id}`, {
          headers: { Authorization: `Bearer ${bearerToken}` },
        });
      } else if (rest_id && !fromDate && toDate) {
        res = await Axios.get(
          `${API_URL}/admin/dashboard?rest_id=${rest_id}&to=${toDate}`,
          {
            headers: { Authorization: `Bearer ${bearerToken}` },
          }
        );
      } else if (rest_id && fromDate && !toDate) {
        res = await Axios.get(
          `${API_URL}/admin/dashboard?rest_id=${rest_id}&from=${fromDate}`,
          {
            headers: { Authorization: `Bearer ${bearerToken}` },
          }
        );
      } else if (rest_id && fromDate && toDate) {
        res = await Axios.get(
          `${API_URL}/admin/dashboard?rest_id=${rest_id}&from=${fromDate}&to=${toDate}`,
          {
            headers: { Authorization: `Bearer ${bearerToken}` },
          }
        );
      }
      await dispatch({
        type: actions.GET_DASHBOARD_FILTER_SUCCESS,
        payload: res,
      });
    } catch (err) {
      dispatch({ type: actions.GET_DASHBOARD_FILTER_FAILED, payload: err });
      toast.error("unable to get dashboard info");
    }
  }
};

export const getDashboardSearchEmptyData = () => {
  return (dispatch) => {
    Axios.get(`${API_URL}/stores?limit=10&page=1`)
    .then((res) => {
      dispatch({
        type: actions.GET_DASHBOARD_DATA_SUCCESS,
        payload: res,
      });
    })
    .catch(() => {
      toast.error("unable to get dashboard info");
    })
  }
}

export const getDashboardSearchData = (value) => {
  return (dispatch) => {
    if(value.trim() === ''){
      dispatch(getDashboardSearchEmptyData());
    } else {
      Axios.get(`${API_URL}/stores?search=${value}`)
      .then((res) => {
        dispatch({
          type: actions.GET_DASHBOARD_DATA_SUCCESS,
          payload: res,
        });
      })
      .catch(() => {
        toast.error("unable to get dashboard info");
      })
    }
  }
}

export const getStoreListPagination = (url) => {
  return (dispatch) => {
    Axios.get(url)
    .then((res) => {
      dispatch({
        type: actions.GET_DASHBOARD_DATA_PAGINATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      toast.error("unable to get dashboard info");
    })
  }
}

export const getStoreGroupListPagination = (url) => {
  return (dispatch) => {
    Axios.get(url)
    .then((res) => {
      dispatch({
        type: actions.GET_RESTAURANT_GROUPS_PAGINATION_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      toast.error("unable to get dashboard info");
    })
  }
}

export const getRestaurantGroups = () => async (dispatch, getState) => {
  dispatch({ type: actions.GET_RESTAURANT_GROUPS_PENDING });
  // const bearerToken = getState().auth.authData.token;
  try {
    const res = await Axios.get(
      `${API_URL}/store_groups?limit=10&page=1`
    );
    await dispatch({
      type: actions.GET_RESTAURANT_GROUPS_SUCCESS,
      payload: res,
    });
    await dispatch(push("/admin/dashboard"));
  } catch (err) {
    dispatch({ type: actions.GET_RESTAURANT_GROUPS_FAILED, payload: err });
    toast.error("unable to get dashboard info");
  }
};

export const deleteStoreGroup = (id) => (dispatch, getState) => {
  
  const token = getState().auth.authData.token;
  Axios
    .delete(`${API_URL}/store_groups/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      dispatch(getRestaurantGroups())
    })
    .catch(() => {})
}

export const fetchSwiggyMenu = (link, cb) => {
  return () => {
    console.log("link", link)
    Axios.post(`${API_URL}/stores/swiggy`,{
      swiggy_link: link
    })
    .then((res) => {
      cb(res.data)
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        toast.error(err.response.data.message);
      }
    })
  }
}
